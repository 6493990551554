import { ExposureHighlight } from '@/store/modules/walkthrough/types/outcomes/resultOverview/highlights/exposureHighlight'

export interface ExposureData {
  exposedToSomeoneKnownToHaveCovid: boolean;
  exposedToSomeoneWithSymptoms: boolean;
}

export class Exposure implements ExposureData {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public exposedToSomeoneKnownToHaveCovid: boolean,
    public exposedToSomeoneWithSymptoms: boolean
  ) {}

  public set (property: keyof ExposureData, value: boolean): void {
    this[property] = value
  }

  public selected (): Array<keyof ExposureData> {
    const result = new Array<keyof ExposureData>()

    this.addIfSelected('exposedToSomeoneKnownToHaveCovid', result)
    this.addIfSelected('exposedToSomeoneWithSymptoms', result)

    return result
  }

  private addIfSelected (typeOfExposure: keyof ExposureData, selected: Array<keyof ExposureData>): void {
    if (!this[typeOfExposure]) {
      return
    }

    selected.push(typeOfExposure)
  }

  public static empty (): Exposure {
    return new Exposure(false, false)
  }

  public any (): boolean {
    return this.exposedToSomeoneKnownToHaveCovid || this.exposedToSomeoneWithSymptoms
  }

  public none (): boolean {
    return !this.any()
  }
}
